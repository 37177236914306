/**********************************************************************

Case Studies Pages

**********************************************************************/
.case-study {
  .study-details {
    @include span(full);
    margin-bottom: em(30px);

    .left {
      width: 50%;
      padding-right: em(15px);
    }

    .right {
      width: 50%;
      padding-left: em(15px);
    }

    img {
      width: auto;
      height: auto !important;
    }
  }
}
