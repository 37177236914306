/**********************************************************************

Search results

**********************************************************************/
body.search-results, body.most-viewed {
  //Set search content container to 100%... because it is... so there
  .content.left {
    width: 100%;
    border-right: 0;
    padding-right: 0;
  }

  .filters {
    margin-left: em(-5px);
    margin-right: em(-5px);
    list-style: none;

    li {
      list-style: none;
      @include span(1 of 8);
      padding: 0 em(5px);
      text-align: center;

      a {
        font-size: em(12px);
        display: block;
        background: #EEF0EC;
        padding-top: em(3px, 12px);
        border-bottom: em(3px, 12px) solid #EEF0EC;
        cursor: pointer;
      }

      &.current a {
        border-bottom-color: #036;
      }
    }
  }
}

.search-listing {
  > p {
    @include span(full);
  }

  article {
    @include span(1 of 3);
    padding: 0 em(7.5px) em(15px);
    position: relative;

    &:nth-of-type(3n+1) {
      padding-left: 0;
      clear: both;
    }

    &:nth-of-type(3n+3) {
      padding-right: 0;
    }

    .article-image {
      @include span(full);
      height: em(180px);
    }

    .txt {
      @include span(full);
      padding: em(10px);
      background: #eaede8;
      padding-bottom: em(40px);
    }

    //Hide for now...
    .url {
      display: none;
      font-size: 0.8em;
      text-decoration: none;
      a {
        border-bottom: 0;
      }
    }

    span.links {
      position: absolute;
      margin-bottom: 0;
      bottom: em(30px);
    }

    strong {
      color: #036;
    }
  }
}