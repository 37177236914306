/**********************************************************************

Case Studies Listing

**********************************************************************/
.case-study-category {
  .bread {
    h1:before {
      content: "Case Studies: "
    }
  }

  > p {
    @include span(full);
  }

  section.casestudy-listing {
    article {
      @include span(full);
      padding: em(15px) 0 0;
    }

    h3 a {
      font-family: "ColaborateLightRegular", Arial, sans-serif;
      font-size: em(22px);
      margin-top: 3.5%;
      margin-bottom: .75em;
    }

    .article-image {
      float: right;
      margin: 0 0 em(15px) em(10px);
    }

    img {
      max-width: 100% !important;
      height: auto !important;
    }
  }
}