header {
    @include span(full);

    padding-top: em(10px);

    .left {
        @include span(em(219px));

        padding: 15px 0 30px 0;

        a {
            display: block;
        }

        //Logo Text replacement
        h4 {
            background: url("../images/logo.svg") top left no-repeat;
            width: 300px;
            height: em(52px);

            span {
                @include visuallyhidden;
            }
        }
    }

    .right {
        text-align: right;

        li {
            display: inline-block;
        }

        a {
            color: #777;
            font-size: em(15px);
            font-family: $colaborate_regular;
            transition: .2s;

            i {
                margin-right: 5px;
                font-size: .9em;
                transition: .2s;
            }

            .fa-sign-out {
                margin-left: 5px;
            }

            &:hover {
                color: #00B2B5;
                text-decoration: none;
            }
        }

        .elect {
            i {
                font-size: 0.8em;
                background: #00B2B5;
                color: white;
                padding: 3px;
                border-radius: 3px;
            }

            a {
                color: #00B2B5;

                &:hover {
                    color: #777;

                    i {
                        background: #777;
                    }
                }
            }
        }

        h2 {
            color: $blue;
            margin-top: em(18px, 33px);
            font-size: 1.25rem;
            font-family: $colaborate_light;
        }
    }

    nav {
        @include span(full);

        position: relative;
    }
}

/* ----------------------------------------------------
 * Main Navigation
 * ---------------------------------------------------- */
ul#menu {
    li {
        &.first {
            padding-left: 0;
            background: none !important;
        }

        &.home .fa {
            @extend %tran--short;

            color: $grey--light;

            &:hover {
                color: $blue;
            }
        }

        .dropdown_4columns {
            display: none;
            transform: translate3d(0, 0, 0);
        }

        a {
            color: #036;
            font-family: $colaborate_thin;

            &:hover {
                text-decoration: none;
            }
        }

        li a, h4 a {
            -webkit-text-fill-color: #001B3D;
        }

        &.last {
            background: none !important;
            float: right;
            padding-right: 0;
            top: -5px;
            position: relative;
        }
    }

    > li {
        padding: 0 em(15px) em(20px);
        display: inline-block;

        &:hover {
            background: url("../images/nav-arrow.svg") center em(30px) no-repeat;

            .dropdown_4columns {
                display: block;
            }
        }
    }

    .data_field {
        width: em(200px, 14px);
        margin-bottom: -10px;
        margin-top: -5px;
        background: url("../images/magnif.svg") calc(100% - 10px) center no-repeat;

        &:focus {
            outline: none;
        }
    }
}

.dropdown_4columns {
    @include span(full);

    font-size: em(14px);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 500;
    padding: 0 em(7.5px, 14px) em(10px, 14px);
    background-color: #EEF0EC;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    margin-top: em(-8px, 14px);

    .header {
        @include span(full);

        padding: em(20px, 14px) 0 0;

        a {
            font-family: $colaborate_medium;
            border-bottom: 1px solid #777;
        }
    }

    > .header {
        padding-left: em(7.5px, 14px);
        padding-right: em(7.5px, 14px);
    }

    div {
        @include span(1 of 4);

        padding: 0 em(7.5px, 14px);
    }

    a {
        display: block;
        line-height: 1.2em;
        margin-bottom: em(10px);
    }
}
