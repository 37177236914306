footer {
  @include span(full);
  padding: em(20px) 0;

  a {
    @extend .fancy-underline;
  }

  .col1 {
    @include span(26%);
    padding-right: em(15px);
  }

  .col2 {
    @include span(37%);
    padding: 0 em(15px);
  }

  .col3 {
    @include span(54%);
    padding: 0 em(15px);

    ul {
      margin: 0;
      @include span(1 of 4);
      font-size: em(14px);
    }

    li {
      margin-bottom: 0.8em;
      line-height: 1.3em;
      list-style: none;
    }
  }

  .col6 {
    @include span(20%);
    padding: 0 em(15px);

    a {
      color: rgb(153, 153, 153);
      i {
        font-size: 1.5em;
        vertical-align: -0.25em;
      }

      &:hover {
        color: #036;
        text-decoration: none;
      }
    }
    ul {
      margin: 0;
    }

    li {
      margin-bottom: 0.5em;
      list-style: none;
    }
  }

  .left {
    padding-top: em(20px);
    width: 60%;
  }

  .right {
    padding-top: em(20px);
    padding-right: 0;
  }

  //Last col is column 4... for some reason
  .col4 {
    @include span(full);
    border-top: 1px solid #777;
    padding-top: 0 !important;
    margin-top: em(40px);
  }

  //Type styles
  .footer-tag {
    margin-bottom: em(12px, 15px);
    padding-bottom: em(5px, 15px);
    width: 100%;
    border-bottom: 1px solid #777;
    color: #666;
    line-height: em(15px, 15px);
  }

  p {
    font-size: em(15px);
    color: #666;
  }

  //Contact form
  input[type="text"] {
    @include span(1 of 2);

    margin-bottom: em(12px, 12px);

  }

  label {
    font-size: em(12px);
  }

  input.btn.grey {
    padding: em(3px, 12px);
    font-size: em(12px);
  }

  //Venue logo
  section img {
    float: left;
    max-width: 100%;
    height: auto !important
  }

  .addthis_default_style {
    height: 20px !important;
    padding: em(11px) em(10px) 0 0 !important;
  }

  .how-to-find-us {
    color: $blue;
    margin-bottom: 20px;
  }

  .social-icons {

    i {
      @extend %tran--extra-short;
      font-size: 1.5rem;
      color: $blue;
    }

    a {
      &:after {
        display: none;
      }

      &:hover {
        i {
          color: black;
        }
      }
    }
  }

}
