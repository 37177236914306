.alt-event-single-container {

    .left-col {
        float: left;
        width: 63%;
    }

    .right-col {
        float: right;
        width: 35%;
    }

}

.alt-form-holder {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.alt-event-form {
    clear: both;
    background: $lightGrey;
    padding: 20px 30px;

    &--invite-only {
        background: #B9E2E5;
        color: #3FB4C7;
        padding: 10px 15px;
        margin-bottom: 20px;
        text-align: center;
    }

    h4 {
        font-size: 20px;
        color: $darkBlue;

        &.guest--title {
            float: left;
        }

        &.guest--number {
            float: right;

            &::after {
                content: "";
                display: block;
                clear: both;
                margin-bottom: 20px;
            }
        }
    }

    .guest {
        input, textarea {
            width: 100%;
        }

        input[type="text"] {
            margin-bottom: 15px;
        }

        textarea {
            margin-bottom: 15px;
            resize: none;
        }
    }
}

.the-guest {
    margin-top: 30px;
    margin-bottom: 15px;

    .guest--row {
        display: flex;
        flex-direction: row;
        align-content: space-around;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__left, &__mid, &__right {
            flex-basis: 30%;
        }

        //&__left {
        //
        //}
        //
        //&__mid {
        //
        //}
        //
        //&__right {
        //
        //}

    }

}
