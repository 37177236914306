/**********************************************************************

Events listings

**********************************************************************/
body.events {
  .filters {
    width: 50%;
    padding-top: 0.7em;

    div.selector.fixedWidth {
      width: 48%;
      float: left;

      &:last-of-type {
        float: right;
      }
    }
  }

  section.events {
    article {
      @include span(full);
      padding: 0 0 em(15px);
    }

    h3 {
      font-size: em(20px);
      margin-top: 3.5%;
      margin-bottom: em(15px, 20px);
    }

    .time p {
      float: right;
      margin: em(-7px) 0 em(10px) em(15px);
      padding: em(6px) em(5px) em(4px);
      width: em(145px);
      color: #fff;
      text-align: center;
      background: #70B2B2;
    }

    img {
      float: right;
      margin: 0 0 em(10px) em(10px);
    }
  }

}