/**********************************************************************

Projects Listing

**********************************************************************/

.project-search {
    @include span(full);
    display: flex;
    flex-flow: row wrap;
    //justify-content: space-between;
    align-items: center;
    padding: em(12px) em(10px) em(10px);
    border-radius: 2px;
    background: #EEF0EC;

    label {
        float: left;
        margin-right: em(10px);
    }

    input {
        font-size: em(11px);
        float: left;
        padding: em(5px, 11px);
        border: 1px solid #777;
        border-radius: 2px;
        color: #333;
        height: em(24px, 11px);
    }

    input[type=submit] {
        font-size: em(11px);
        position: relative;
        top: 0;
        left: em(-22px, 11px);
        width: em(19px, 11px);
        height: em(17px, 11px);
        border: 0;
        background-image: url('../images/magnif.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #FFF;
        cursor: pointer;
    }
    span {
        position: relative;
        float: left;
        margin-left: em(10px, 14px);
        color: #777;
        font-size: em(14px);
    }
}

.projects {
    //.panel {
    //  display: flex;
    //  flex-flow: row wrap;
    //  justify-content: space-between;
    //  align-items: center;
    //}

    .option {
        //flex-basis: 15%;
        padding-right: 15px;
    }

    .advanced-search {
        background: #EEF0EC;
        padding-right: em(10px);
        padding-left: em(10px);
        position: relative;

        a.advanced:after {
            content: '';
            background: url('../images/advanced-search-icon.svg') right center no-repeat;
            display: inline-block;
            height: 1em;
            width: 1em;
            vertical-align: -0.2em;
            margin-left: 0.2em;
        }

        .option {
            width: 25%;

            &:nth-of-type(3n+3) {
                padding-right: em(10px);
            }

            &:nth-of-type(3n+1) {
                clear: none;
                padding-left: em(10px);
            }

            &:nth-of-type(4n+1) {
                clear: none;
                padding-left: 0;
            }

            &:nth-of-type(4n+4) {
                padding-right: 0;
            }

        }

        &:after {
            content: '';
            top: 0;
            right: em(10px);
            left: em(10px);
            height: 1px;
            border-top: 1px dotted #777;
            position: absolute;
        }
    }
}

.project-listing {
    @include span(full);
    padding-bottom: em(20px);

    .project-tile {
        @include span(full);
        padding-top: em(20px);
        padding-bottom: em(20px);
        border-bottom: 1px dotted #777;

        h3 {
            font-size: em(18px);
            line-height: 110%;
            font-family: $colaborate_regular;

            a {
                font-family: $colaborate_regular;
            }
        }

        .left {
            width: span(4 of 5);
            padding-right: em(40px);
        }

        .right {
            width: span(1 of 5);
        }

        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }
}