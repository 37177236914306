/**********************************************************************

Elements

**********************************************************************/

input.btn, a.btn-blue, button.btn {
  margin-top: .5882352941em;
  padding: .5882352941em;
  font-size: 1em;
  margin-bottom: .2941176471em;
  display: inline;
  width: auto;
  border: 0;
  border-radius: 2px;

  font-family: "ColaborateMediumRegular", Arial, sans-serif;
  cursor: pointer;
  background: $blue;
  color: #FFF;

  .fa {
    margin-right: 10px;
  }

  &.grey {
    background: linear-gradient(to bottom, #666 0, #999 100%);
  }
}

a.more {
  @extend .fancy-underline;
  //box-sizing: content-box !important;
  //margin: -.2em 0;
  //padding: .2em 0 .1333333333em;
  //border-bottom: 1px dotted #036;
  //font-size: .8823529412em;
  //line-height: 1.4666666667em;
  //font-family: "ColaborateRegular", Arial, sans-serif;
  //
  //&:hover {
  //  margin: -.2em;
  //  padding: .2em .2em .1333333333em;
  //  border-bottom: 1px solid #036;
  //  background: #036;
  //  color: #fff;
  //  text-decoration: none;
  //}
}

//a.more.forward {
//  float: right;
//  position: relative;
//  padding: .2em 1.4em .1333333333em 0 !important;
//
//  &:after {
//    position: absolute;
//    top: .2em;
//    right: .2em;
//    height: 1em;
//    width: 1em;
//    content: url(../images/down-arrow-sml.svg);
//  }
//
//  &:hover {
//    padding: .2em 1.4em .1333333333em .2em !important;
//  }
//}

.bread {
  @include span(full);
  font-size: .8235294118em;
  color: #666;
  padding-bottom: .5882352941em;

  ul {
    @include span(full);
    margin: 0 !important;
    padding-bottom: em(10px);

    li {
      display: inline-block;
      list-style: none;
      padding: 2px 5px 2px 0;

      &.bold {
        font-family: $colaborate_medium;
      }

      a {
        border-bottom: none;
      }
    }
    li.arrow a:after {
      position: relative;
      top: 2px;
      margin-right: 0;
      margin-left: 0;
      font-size: 10px;
    }
  }
}

//Dividing line for content pages header
.hr-top {
  @include span(full);
  padding-bottom: em(15px);
  border-top: 1px dotted #777;
}

.bluebox {
  @include span(full);
  margin-bottom: em(15px);
  padding: em(17px) em(15px) em(3px);
  background-color: #036;
  color: #FFF;
}

div.hr {
  float: left;
  clear: both;
  padding-bottom: em(10px);
  width: 100%;
  border-top: 1px solid #777;
}

.social-share, .addthis_default_style {
  @include span(full);

  a {
    display: inline-block;
  }
}

//Twitter
ul.tweet_list {
  li {
    @include span(full);
    color: #666;
    font-size: em(16px);

    .cta-holder-right & {
      border: 1px solid #eee;
      padding: em(10px);
      margin-bottom: 0.5em;
    }

    .tweet_text, .tweet_time {
      display: block;
      a {
        margin: -2px;
        padding: 2px 2px 1px;
        @extend .fancy-underline;
      }
    }
  }

  a.tweet_avatar {
    float: left;
    margin: 0 em(10px) em(85px) 0;
  }
}