.alt-list-header {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  width: 100%;

  &__left {
    flex-basis: 40%;
  }

  &__right {
    flex-basis: 60%;

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
    }

    .select2-container {
      width: 40% !important;
    }
  }
}

.alt-event-listing {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: $lightGrey;
  margin-bottom: 10px;
  padding: 25px;

  &__image {
    flex-basis: 150px;

    img {
      height: 150px;
    }
  }

  &__location {
    background: #CCCDCA;
    color: #FFFFFF;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    font-family: $colaborate_medium;
    font-size: 1.2em;
  }

  &--header {
    h2 {
      font-family: $colaborate_medium;
      font-size: 1.4rem;
      padding-top: 0;
    }
  }

  &__info {
    flex-basis: calc(100% - 175px);
    color: $midDarkBlue;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-content: space-around;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &--left {
      flex-basis: 70%;

      h3 {
        font-size: 20px;
      }
    }

    &--right {
      flex-basis: 20%;

      .alt-btn {
        text-align: center;
      }
    }
  }

  &--timeLocation {
    margin: 10px 0;
  }

  &--invite-only {
    background: #B9E2E5;
    padding: 15px 10px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 2px;
  }
}
