/**********************************************************************

Crazy forms

**********************************************************************/

//These are made of spaghetti code... abandon all hope...

#newFelloForm, .mentor-nominations, .evidence-form-2015, .evidence-form-2015-2 {
    ul.results {
        list-style: none;
        margin-left: 0;

        li {
            list-style: none;
        }

        p {
            margin-bottom: 0.2em;
        }
    }

    strong span {
        font-family: $colaborate_regular;
        display: inline-block;
        width: 100%;
    }

    .formlist {
        li {
            @include span(full);
            margin-bottom: 1em;
        }

        //Intro Override
        &.intro {
            li {
                margin: 0;
            }
        }

        &.two-column {
            margin-top: em(20px);
            @include span(full);
            li {
                width: 50%;
                &:nth-of-type(2n+1) {
                    padding-right: em(10px);
                }

                &:nth-of-type(2n+2) {
                    padding-left: em(10px);
                }
            }
        }

        .jqte {
            margin: em(20px) 0;
        }

        .horz {
            label {
                width: 30%;
            }

            small {
                @include span(full);
                color: #999;
                padding-left: 30%;
            }

            input, textarea {
                @include span(70%)
            }

            .jqte {
                @include span(70%);
                margin: 0 0 em(5px);
                .jqte_editor {
                    min-height: em(100px);
                }
            }
        }

        .full-jqte {
            .jqte {
                width: 100%;
            }
        }

        //Intro Override
        &.intro {
            .horz {
                label {
                    width: 100%
                }

                input, textarea {
                    width: 100%;
                }
                small {
                    padding-left: 0;
                }
            }
        }

        label {
            @include span(full);
            font-size: em(15px);
            line-height: em(15px, 13px);
            margin-top: em(8px, 13px);
            margin-bottom: em(5px, 13px);
        }

        span.req {
            color: red;
        }

        .errorText {
            float: left;
            color: red;
        }
        .error {
            input {
                background: #EDAFAF;
            }
        }

        span.num {
            font-size: em(12px);
            line-height: em(37px, 12px);
            font-weight: 400;
            font-family: 'ColaborateMediumRegular', Arial, sans-serif;
            background: #036;
            color: #FFF;
            border-radius: 50%;
            width: em(36px, 12px);
            height: em(36px, 12px);
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: 0;

            &.alt {
                background: #FFF;
                color: #036;
                border: 2px #036 solid;
                line-height: em(33px, 12px);
            }
        }

        &.numbered {
            p {
                position: relative;
                padding-top: em(8px);
                padding-left: em(42px);
                margin-bottom: em(12px);
            }

            .jqte {
                margin-left: em(42px);
            }
        }
    }

    //Not grey here... go figure
    input.btn.grey {
        font-size: em(22px);
        float: right;
        padding-top: 0.5em;
        padding-bottom: 0.4em;
        background: linear-gradient(to bottom, #036 0%, #004993 100%);
    }
}

/****************************
Fellow Form conditionals
Madder than a bee in a tumbledryer
*****************************/

#newFelloForm #sectionB p {
    display: none;
}

#newFelloForm #B1, #newFelloForm #B2, #newFelloForm #B3, #newFelloForm #B4 {
    display: none;
}

#newFelloForm #sectionC, #newFelloForm #sectionD, #newFelloForm #sectionE {
    display: none;
}

#newFelloForm #sectionC p {
    display: none;
}

#newFelloForm #C1, #newFelloForm #C2, #newFelloForm #C3, #newFelloForm #C4, #newFelloForm #C5, #newFelloForm #C6, #newFelloForm #C7, #newFelloForm #C8 {
    display: none;
}

#newFelloForm #sectionE p {
    display: none;
}

#newFelloForm #E1, #newFelloForm #E2, #newFelloForm #E3, #newFelloForm #E4, #newFelloForm #E5 {
    display: none;
}

/*Area of work conditions*/
#newFelloForm .A1-2 #sectionB p,
#newFelloForm .A1-3 #sectionB p,
#newFelloForm .A1-4 #sectionB p,
#newFelloForm .A1-5 #sectionB p {
    display: block;
}

#newFelloForm .A1-1 #sectionD, #newFelloForm .A1-1 #sectionE,
#newFelloForm .A1-2 #B1, #newFelloForm .A1-2 #section,
#newFelloForm .A1-3 #B2, #newFelloForm .A1-3 #sectionC,
#newFelloForm .A1-4 #B3, #newFelloForm .A1-4 #sectionC,
#newFelloForm .A1-5 #B4, #newFelloForm .A1-5 #sectionC {
    display: block;
}

/*Major specialisatio - Clinical conditions*/
#newFelloForm .B1-4 #sectionC p,
#newFelloForm .B1-5 #sectionC p,
#newFelloForm .B1-6 #sectionC p,
#newFelloForm .B1-7 #sectionC p,
#newFelloForm .B1-8 #sectionC p,
#newFelloForm .B1-10 #sectionC p,
#newFelloForm .B1-11 #sectionC p,
#newFelloForm .B1-12 #sectionC p {
    display: block;
}

#newFelloForm .B1-4 #sectionC, #newFelloForm .B1-5 #sectionC, #newFelloForm .B1-6 #sectionC, #newFelloForm .B1-7 #sectionC, #newFelloForm .B1-8 #sectionC, #newFelloForm .B1-10 #sectionC, #newFelloForm .B1-11 #sectionC, #newFelloForm .B1-12 #sectionC {
    display: block;
}

#newFelloForm .B1-4 #C1, #newFelloForm .B1-5 #C2, #newFelloForm .B1-6 #C3, #newFelloForm .B1-7 #C4, #newFelloForm .B1-8 #C5, #newFelloForm .B1-10 #C6, #newFelloForm .B1-11 #C7, #newFelloForm .B1-12 #C8 {
    display: block;
}

/*Scientific speciality conditions*/
#newFelloForm .D1-11 #sectionE p,
#newFelloForm .D1-26 #sectionE p,
#newFelloForm .D1-27 #sectionE p,
#newFelloForm .D1-32 #sectionE p,
#newFelloForm .D1-34 #sectionE p {
    display: block;
}

#newFelloForm .D1-11 #E1, #newFelloForm .D1-26 #E2, #newFelloForm .D1-27 #E3, #newFelloForm .D1-32 #E4, #newFelloForm .D1-34 #E5 {
    display: block;
}

/*Evidence*/
#roletype #section2, #roletype #section3, #roletype #section4, #roletype #section5 {
    display: none;
}

#roletype #endofform {
    display: none;
}

#roletype #endofform {
    display: none;
}

#roletype.A1 #endofform,
#roletype.A2 #endofform,
#roletype.A3 #endofform,
#roletype.A4 #endofform,
#roletype.A5 #endofform {
    display: block;
}

/*Evidence conditions*/
#roletype.A1 #section2,
#roletype.A2 #section3,
#roletype.A3 #section4,
#roletype.A4 #section5 {
    display: block;
}

#roletype #bul-a,
#roletype #bul-b,
#roletype #bul-c,
#roletype #bul-d {
    display: none;
}

/*Not 1*/
#roletype.A1 #bul-b {
    display: none !important
}

#roletype.A2 #bul-b,
#roletype.A3 #bul-b,
#roletype.A4 #bul-b,
#roletype.A5 #bul-b {
    display: block;
}

/*Not 2*/
#roletype.A2 #bul-d {
    display: none !important
}

#roletype.A1 #bul-d,
#roletype.A3 #bul-d,
#roletype.A4 #bul-d,
#roletype.A5 #bul-d {
    display: block;
}

/*Not 3*/
#roletype.A3 #bul-a {
    display: none !important
}

#roletype.A1 #bul-a,
#roletype.A2 #bul-a,
#roletype.A4 #bul-a,
#roletype.A5 #bul-a {
    display: block;
}

/*Not 4*/
#roletype.A4 #bul-c {
    display: none !important
}

#roletype.A1 #bul-c,
#roletype.A2 #bul-c,
#roletype.A3 #bul-c,
#roletype.A5 #bul-c {
    display: block;
}

.hiddenEnd, .hiddenEndTwo {
    display: none;
}