/**********************************************************************

News Listing

**********************************************************************/

#newsfilters {
  @include span(full);

  .sortoptions {
    float: left;
    min-width: em(285px);
    div.selector {
      width: 70%;
    }
  }

  .dateoptions {

    //div.selector {
    //  width: 70%;
    //  float: right;
    //}
  }

  .selector > span {
    width: 100%;
  }

  p {
    float: left;
    font-size: em(17px);
    line-height: 170%;
    margin-right: em(10px, 17px);
  }

  div.selector {
    float: left;
  }
}

.news-listing {
  article {
    @include span(39%);
    height: em(383px);
    background: #eaede8;
    border: solid;
    border-color: #fff;
    border-width: 0 em(8px) em(16px) em(8px);
    position: relative;

    &:nth-of-type(3n+1) {
      border-left: none;
    }

    &.last {
      border-right: 0;
    }

    time {
      position: absolute;
      top: 0;
      right: 0;
      font-size: em(14px);
      line-height: em(18px, 14px);
      padding: em(6px) em(8px) em(5px);
      background: rgba(0, 51, 102, 0.8);
      color: #fff;
    }

    p.sub {
      @include span(full);
      overflow: hidden;
      margin: em(14px, 18px) 0 em(6px, 18px);
      font-size: em(18px);
      a {
        font-family: $colaborate_regular;
        margin: auto;
        padding: 0;
        border-bottom: 0;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .info p {
      font-size: em(14px);
      line-height: em(18px, 14px);
    }

    a.more {
      position: absolute;
      bottom: em(13px);
      left: em(13px);
    }

    //Types
    &.main {
      .article-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;

        a {
          display: block;
          width: 100%;
          height: 100%;
        }

        p.sub {
          position: absolute;
          top: 65%;
          right: 0;
          bottom: 0;
          left: 0;
          margin: 0;
          background: $blue;

          a {
            color: #fff !important;
            font-size: em(28px);
            line-height: 1.05em;
            padding: em(18px, 28px) em(13px, 28px);
          }
        }
      }
    }

    &.notmain {
      .article-image {
        @include span(full);

        .image {
          @include span(full);
          padding-bottom: 55%;
          position: relative;
        }
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
      p.sub {
        margin-top: em(13px);
        padding: 0 em(13px);
      }

      .info {
        @include span(full);
        padding: 0 em(13px);
      }

      &.last {
        //Have to tweak the image size of the last because of crazy heirachy
        .article-image .image {
          padding-bottom: 53.7%;
        }
      }
    }

    &.mini {
      width: 22%;
      padding: em(13px);
      p.sub {
        margin-top: em(30px);
        margin-bottom: em(20px);
      }
    }

  }

  div.summary {
    @include span(39%);
    height: em(383px);
    background: #eaede8;
    border: solid;
    border-color: #fff;
    border-width: 0 0 em(16px) em(8px);
    position: relative;
    padding: em(13px);

    h2 {
      margin: 0;
      color: rgb(0, 51, 102);
      font-size: em(28px);
      font-family: $colaborate_regular;
    }

    a {
      @include span(full);
      margin: em(23px) 0 0;
      color: $colaborate_regular;

      h3 {
        @include span(60%);
        font-size: em(15px);
        font-family: 'ColaborateRegular', Arial, sans-serif;
      }

      time {
        @include span(40% last);
        text-align: right;
        font-size: em(12px);
        line-height: em(18px, 12px);
      }
    }
  }

}