@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

@mixin placeholder {
  // These can't just be extended
  @each $prefix in "::-webkit-input", ":moz", "::-moz", ":-ms-input" {
    #{$prefix}-placeholder {
      @content;
    }
  }
}

@mixin line {
  display: inline-block;
  width: 100%;
  height: $button-size/7;
  background: #036;
  transition: $transition;
}

/* ----------------------------------------------------
 * Em Calculator Mixin
 * ---------------------------------------------------- */
@function em($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0
  }
  $target: $target  / ($target  * 0 + 1); // Strip off units to be sure we have a plain number. (eg: 20px -> 20)
  $context: $context / ($context * 0 + 1); // Strip off units to be sure we have a plain number. (eg: 20px -> 20)
  @return $target / $context * 1em; // The *1em ensures we return an EM number.
}

/* ===============================
 * Mobile first media query
 * =============================== */
@mixin min($min-width) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

/* ===============================
 * Desktop first media query
 * =============================== */
@mixin max($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

/* ===============================
 * Shorthand transform:translate(x,y)
 * =============================== */
@mixin move($moveX, $moveY) {
  transform: translate($moveX, $moveY);
}

/* ===============================
 * Shorthand transform:translateY(y)
 * =============================== */
@mixin moveX($moveX) {
  transform: translateX($moveX);
}

/* ===============================
 * Shorthand transform:translateX(x)
 * =============================== */
@mixin moveY($moveY) {
  transform: translateY($moveY);
}

/* ===============================
 * Extra short transition
 * =============================== */
%tran--extra-short {
  transition: .2s;
}

/* ===============================
 * Short transition
 * =============================== */
%tran--short {
  transition: .5s;
}

/* ===============================
 * Medium transition
 * =============================== */
%tran--mid {
  transition: .75s;
}

/* ===============================
 * Long transition
 * =============================== */
%tran--long {
  transition: 1s;
}

.fancy-underline {
  @extend %tran--extra-short;
  position: relative;
  text-decoration: none !important;

  &:after {
    @extend %tran--extra-short;
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    background: currentColor;
    width: 0;
  }

  &:hover {
    &:after {
      left: 0;
      width: 100%;
    }
  }
}