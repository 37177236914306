a {
  color: #036;

  &:hover {
    text-decoration: underline;
  }
}

h1 {
  margin-bottom: em(10px, 33px);
  font-size: em(33px);
  line-height: 110%;

  .col.content & {
    font-family: $colaborate_regular;
  }
}

// Why page titles are in the bread... don't ask
.bread {
  h1 {
    color: #333
  }
}

h2 {
  font-size: em(33px);
  margin-bottom: em(10px, 22px);
  line-height: 110%;
}

h3 {
  font-family: $colaborate_light;
  line-height: 110%;
  margin-bottom: em(5px);
}

p.sub {
  font-family: $colaborate_regular;
  margin: em(10px, 25px) 0 em(15px, 25px);
  font-size: em(25px);
  line-height: 110%;
}

h5 {
  font-size: em(22px);
  line-height: 110%;
}

//Heading like styling
p.intro {
  font-size: em(24px);
  line-height: 120%;
}

time {
  margin-bottom: em(6px);
}

small {
  font-size: em(13px);
}

.sml {
  font-size: em(12px);
}

strong {
  font-family: $colaborate_regular;
}

i {
  font-style: italic;
}

p {
  margin-bottom: em(15px, 17px);
  font-size: em(17px);
  line-height: 140%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $colaborate_regular;
}