/**********************************************************************

Content Pages

**********************************************************************/

.content.left:not('.project-images') img{
  width: auto !important;
  height: initial !important;
}

article.standard, .tabbed-content-holder, .content {
  @include span(full);
  margin-bottom: em(25px);

  .realted-items {
    @include span(full);
    padding-bottom: em(20px);
  }

  .standard, .tabbed-content {
    img {
      max-width: 100%;
      height: auto !important;
    }
    iframe {
      max-width: 100% !important;
      width: 100vw !important;
      max-height: 56.25vw !important;
    }
  }

  ul.tabs, ul.tabs-lrg {
    @include span(full);
    margin: 0 0 em(10px);

    li {
      list-style: none;
      display: inline;
      float: left;
      padding: 0 em(10px) 0;
      border-left: 1px solid #777;

      &:first-child {
        padding-left: 0;
        border-left: none;
      }

      &.active {
        a {
          font-family: $colaborate_regular;
          border-bottom: 1px dotted #FFF;
          opacity: 1;
        }
      }

      a {
        border-bottom: 1px dotted #777;
        opacity: 0.8;
        color: #333;

        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }
    }
  }

  em {
    font-style: italic;
  }

  code {
    font-family: monospace;
  }

  // Form exception for UL and LI
  form {
    ul {
      margin: 0;
      li {
        list-style: none;
      }
    }
  }

  p, ul, ol, em, strong {
    a {
      @extend .fancy-underline;
    }
  }

  strong, strong * {
    font-family: "ColaborateRegular", Arial, sans-serif;
  }

  ol {
    font-size: em(17px);
    margin-right: em(25px, 17px);
    margin-bottom: em(15px, 17px);
    margin-left: em(19px, 17px);
    line-height: 140%;

    li {
      margin-bottom: em(5px, 17px);
      list-style: decimal;
    }
  }

  ul {
    font-size: em(17px);
    margin-right: em(25px, 17px);
    margin-bottom: em(15px, 17px);
    margin-left: em(19px, 17px);
    line-height: 140%;

    li {
      margin-bottom: em(5px, 17px);
      list-style: url("../images/bullet.svg");
    }
  }
}

section.related-event {
  article {
    @include span(full);
    padding: 0 0 em(15px);
  }

  h3 {
    font-size: em(20px);
    margin-top: 3.5%;
    margin-bottom: em(15px, 20px);
  }

  .time p {
    float: right;
    margin: em(-7px) 0 em(10px) em(15px);
    padding: em(6px) em(5px) em(4px);
    width: em(165px);
    color: #fff;
    text-align: center;
    background: #70B2B2;
  }

  img {
    float: right;
    margin: 0 0 em(10px) em(10px);
  }
}

//Pagination
.pagination {
  @include span(full);

  ul {
    margin: 0;
    list-style: none;
  }

  li {
    display: inline;
    float: left;
    margin-right: em(5px);

    a, span {
      display: block;
      padding: em(5px);
      border-bottom: 1px solid #777;
      background-color: #EEF0EC;
      text-decoration: none;

      &.selected {
        background: #036;
        color: #fff;
      }

      &:hover {
        border-bottom: 1px solid #036;
        background-color: #DADCD8;
        padding: .2941176471em;
      }
    }

    &.active {
      a, span {
        border-bottom: 1px solid #036;
        background-color: #DADCD8;
      }
    }

    &.deactive {
      padding: em(5px);
      border-bottom: 1px solid #777;
      background-color: #EEF0EC;
      opacity: .5;
      margin-top: -2px;
    }
  }

  .viewall .left {
    margin-top: em(5px);
  }
}