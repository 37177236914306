/**********************************************************************

Homepage

**********************************************************************/

//Carousel

.hero.home {
  @include span(full);
  height: 0;
  padding-bottom: 33.062%;
  position: relative;
  margin-bottom: em(50px);
}

.article-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slideshow {
  height: 100%;
}

//Slick imports!

@import "./bower_components/slick.js/slick/slick.scss";
@import "_slick-theme.scss";

.slide {
  @include span(full);
  height: 100%;
  display: none;

  &:nth-of-type(1) {
    display: block;
  }

  article {
    float: left;
    overflow: hidden;
    position: relative;

    //Article Types
    &.full {
      width: 100%;
      height: 100%;

      div {
        width: 40%;
      }
    }

    &.main {
      width: 65.8%;
      height: 100%;
    }

    &.sub {
      float: right;
      width: 32.65%;
      height: 47.83%;
      margin-bottom: 1.5%;

      div {
        width: 90%;
      }
    }

    &.sub-lrg {
      width: 32.65%;
      height: 100%;

      div {
        width: 70%;
      }
    }

    &.sub-lrg + article.main {
      float: right;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }

    img {
      width: 100%;
      height: auto;
      transition: opacity 0.5s ease;
    }

    div {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 400;
      min-height: em(70px);
      padding: em(10px);
      width: 60%;
      color: #FFF;

      transition: top 1s ease-in-out;

      h3 {
        font-size: em(18px);
      }

      span {
        font-size: em(16px);
        line-height: 1.1em;
        display: none;
      }

    }

    a:hover {
      img {
        opacity: 0.2;
      }
    }
  }
}

.slide-navigation {
  position: absolute;
  bottom: em(-48px);
  width: inherit;
  border-top: 1px solid #FFF;
  background-color: #EEF0EC;
  text-align: center;

  ul {
    position: relative;
    display: inline;
    margin: auto;
    width: auto;
  }

  li {
    @include span(1 of 7);
    color: #666;
    font-size: em(15px);
  }

  a {
    padding: em(5px, 15px) 0;
    color: #666;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
}

a.more-news-home {
  float: right;
  position: relative;
  padding: em(3px, 15px) em(21px, 15px) em(2px, 15px) 0 !important;

  &:after {
    position: absolute;
    top: em(3px, 15px);
    right: em(3px, 15px);
    height: 1em;
    width: 1em;
    //content: url("../images/down-arrow-sml.svg");
  }

  &:hover {
    padding: em(3px, 15px) em(21px, 15px) em(2px, 15px) em(3px, 15px) !important;
  }
}

.home {
  .body {
    &:before {
      content: '';
      background: #000;
      top: 0;
      bottom: 0;
      position: absolute;
      width: 1px;
      right: 57.5%;
    }
    &:after {
      content: '';
      background: #000;
      top: 0;
      bottom: 0;
      position: absolute;
      width: 1px;
      right: 28.5%;
    }
  }

  article.standard {
    margin-bottom: 0;
    ul {
      margin-bottom: 0;
    }
  }

  .primary {
    @include span(3 of 7);
    padding-right: em(15px);
  }
  .secondary {
    @include span(2 of 7);
    padding: 0 em(15px);
    &:last-of-type {
      padding-right: 0;
    }
  }
}
