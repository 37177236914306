/**********************************************************************

Fellow Login Popup

**********************************************************************/
#fellowlogin {
  .left {
    width: 50%;
    padding-right: em(7.5px);
  }
  .right {
    width: 50%;
    padding-left: em(7.5px)
  }
  form {
    margin-top: em(24px);
  }
  ul {
    margin: 0;
    list-style: none;
  }
  label {
    @include span(full);
  }
  input {
    @include span(full);

    margin-bottom: em(10px, 12px);

  }

  .remember {
    label {
      width: auto;
      margin-right: em(5px);
    }
  }

  button {
    float: left;
    clear: both;
  }
}

.content {
  #loginform {
    ul {
      margin: 0;
    }

    li {
      list-style: none;
    }

    label {
      @include span(full);
    }

    input {
      font-size: em(12px);
      width: em(220px, 12px);

      &[type=text] {
        border: 1px solid #777;
        font-family: Arial, sans-serif;
      }
    }
  }
}