/**********************************************************************

Tabs

**********************************************************************/
.tab-container {

  article {
    @include span(full);
    margin: em(10px) 0;

    a {
      display: inline-block;
      text-decoration: none;

      &:hover {

        h3 {
          text-decoration: underline;
        }
      }
    }

    time {
      display: inline-block;
      font-size: em(16px);
    }
  }

}