/**********************************************************************

Staff Directory

**********************************************************************/
.staff-directory {
  .two-column-list {
    p.sub {
      @include span(full);
    }
    ul {
      @include span(full);
    }
    li {
      @include span(1 of 2);
      padding-right: em(15px);

      &.full {
        width: 100%;
      }
      &:nth-of-type(2n+4) {
        clear: both;
      }
    }
    h3 {
      font-size: em(18px);
      line-height: 110%;
    }
  }

  ul{
    padding-left: 0;
    margin-left: 0;

    li{
      list-style: none;
      h3{
        font-family: ColaborateLightRegular, Arial, sans-serif;
      }
    }
  }
}