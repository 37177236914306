@function em($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0
  }
  $target: $target  / ($target  * 0 + 1); // Strip off units to be sure we have a plain number. (eg: 20px -> 20)
  $context: $context / ($context * 0 + 1); // Strip off units to be sure we have a plain number. (eg: 20px -> 20)
  @return $target / $context * 1em; // The *1em ensures we return an EM number.
}

$button-size : em(48px);

/**********************************************************************

Responsive

**********************************************************************/

/* @media screen and (max-width: 850px) */
@media screen and (max-width: 850px) {
  //Grid
  html {
    font-size: 15px;
  }

  //Grid
  .wrapper {
    padding-top: em(20px);
  }

  //Header
  .navbar ul#menu {
    display: none;
  }

  header {
    .left {
      h4 {
        margin-left: 4em;
      }
    }
    .right {
      h2 {
        font-size: 1em;
      }
    }
  }

  #menu-toggle {
    position: absolute;
    top: em(48px);
    left: em(40px);
    display: inline-block;
    transition: .3s;
    cursor: pointer;
    user-select: none;
    width: $button-size + em(10px);
    height: $button-size + em(10px);
    padding: em(10px);

    &:hover {
      opacity: 1;
    }

    &:active {
      transition: 0;
      background: rgba(0, 0, 0, .1);
    }
  }

  .lines {
    //create middle line
    @include line;
    position: relative;
    margin-top: $button-size/3.2;

    /*create the upper and lower lines as pseudo-elements of the middle line*/
    &:before, &:after {
      @include line;
      position: absolute;
      left: 0;
      content: '';
      transform-origin: $button-size/14 center;
    }
    &:before {
      top: $button-size/4;
    }
    &:after {
      top: -$button-size/4;
    }
  }

  .lines-button.x.close .lines {
    /*hide the middle line*/
    background: transparent;

    /*overlay the lines by setting both their top values to 0*/
    &:before, &:after {
      transform-origin: 50% 50%;
      top: 0;
      width: $button-size;
    }

    // rotate the lines to form the x shape
    &:before {
      transform: rotate3d(0, 0, 1, 45deg);
    }
    &:after {
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }

  .main.wrapper .container {
    margin-top: 8em;
  }

  ////// OFF SCREEN NAV //////
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    background: #fff;
    transition: transform .15s cubic-bezier(.2, .3, .25, .9);

    .nav-open & {
      transform: translate3d(em(340px), 0, 0);
    }
  }

  body {
    .nav-open & {
      overflow-y: hidden;
    }
  }

  nav#offscreen-nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: em(340px);
    background: #EEF0EC;
    transform: translate3d(em(-340px), 0, 0);
    overflow-y: auto;
    overflow-scrolling: touch;
    z-index: 900;
    font-family: $colaborate_thin;

    //Prevent IOS accidentally overiding the javascipt show hide
    ul#menu li .dropdown_4columns {
      display: none;
    }

    .nav-open & {
      transform: translate3d(0, 0, 0);
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
      border-right: 1px solid darken(#EEF0EC, 3%);
    }

    ul {
      @include span(full);
      position: relative;
      padding-top: 2.75em;
      border-bottom: 1px solid darken(#EEF0EC, 8%);

      ul {
        padding-top: 0;
      }

      //Search box
      li.last {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        float: none;
        padding: em(5px) em(10px) em(5px) 0;
        background-color: lighten(#EEF0EC, 5%) !important;
      }

      input.data_field {
        font-size: 1.4em;
        width: 100%;
        padding: em(10px) em(20px) em(7px);
        border-bottom: none;
      }

      li {
        @include span(full);
        width: 100%;
        padding: 0;
        position: relative;
        border-top: 1px solid darken(#EEF0EC, 8%);

        a {
          @include span(full);
          font-size: 1.4em;
          padding: em(10px) em(20px) em(7px);
          font-family: $colaborate_medium;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          height: 2.7em;
          width: 2.7em;
          background: darken(#EEF0EC, 15%);

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 2.5em;
            width: 2.5em;
            background: url('../images/menu-arrow.svg') center center no-repeat;
            transform: rotate(0deg);
            transition: all 0.2s ease-in-out;
          }

          &.open:after {
            transform: rotate(90deg);
          }
        }
      }

      li .dropdown_4columns {
        background: darken(#EEF0EC, 5%);
        position: relative;
        box-shadow: none;
        margin-top: 0;
        padding: 0;
        font-size: 1em;

        .header {
          display: none;
        }

        div {
          width: 100%;
          padding: 0;

          .header {
            display: inline-block;
            border-bottom: none;
            background: darken(#EEF0EC, 8%);
            a {
              border-bottom: none;
              font-family: $colaborate_medium;
            }
          }

        }

        a {
          display: block;
          line-height: 1em;
          margin-bottom: 0;
          font-family: $colaborate_thin;
        }
      }
    }
  }

  //Fellow Login Popup
  #fellowlogin {
    .left {
      width: 100%;
      padding-right: 0;
    }
    .right {
      width: 100%;
      padding-left: 0
    }
  }

  //Home
  .hero.home {
    padding-bottom: 50%;
  }

  .slide {
    article.sub {
      margin-bottom: 2.1%;
    }
  }

  .slide-navigation li {
    font-size: .6666666667em;
  }

  .home {
    .body {
      &:before {
        content: '';
        display: none;
      }
      &:after {
        content: '';
        display: none;
      }
    }

    .primary {
      width: 100%;
      padding-right: 0;
      margin-bottom: em(30px);
    }
    .secondary {
      width: 50%;
    }

    .secondary:nth-of-type(2) {
      padding-left: 0;
      border-right: 1px solid #111;
    }
  }

  //Fellows listing pages/ case studies....
  .case-study-list {
    article {
      width: 50%;
      border-bottom: em(10px) solid #fff;
      overflow: hidden;
    }
  }

  //News Listing
  .news-listing {
    article {
      width: 50%;

      &:nth-of-type(3n+1) {
        border-right: em(8px) solid #fff;
      }

      &:nth-of-type(2n+1) {
        border-left: 0;
        border-right: em(8px) solid #fff;

        &.last {
          border-left: 0;
          border-right: em(8px) solid #fff;
        }
      }

      &:nth-of-type(2n+2) {
        border-left: em(8px) solid #fff;
        border-right: 0;

        &.last {
          border-left: em(8px) solid #fff;
          border-right: 0;
        }
      }

      &.mini {
        width: 50%;
      }
    }

    div.summary {
      width: 100%;
      border-right: 0;
      border-left: 0;
      height: auto;
    }
  }

  //Fellows Listing
  .fellows-directory {

    .advanced-search {

      .option {
        width: 50%;
        //Cancel previous rule
        &:nth-of-type(3n+1) {
          padding-left: em(10px);
          clear: none;
        }

        &:nth-of-type(3n+3) {
          padding-right: em(10px);
        }

        //New rule
        &:nth-of-type(2n+1) {
          padding-left: 0;
          clear: both;
        }

        &:nth-of-type(2n+2) {
          padding-right: 0;
        }
      }
    }
  }

  //Projects Listings
  .project-search {

    span {
      @include span(full);
      margin-left: 0;
      margin-top: em(5px);
    }
    input[type=search] {
      width: 90%;
      font-size: 0.7em;
    }
    input[type=submit] {
      font-size: 0.7em;
    }

  }
  body.projects {
    .advanced-search {
      .option {
        width: 100%;

        &:nth-of-type(4n+1) {
          padding-left: em(10px);
        }

        &:nth-of-type(4n+4) {
          padding-right: em(10px);
        }
      }
    }
  }

  //Projects and Funding Pages
  .funding-detail, .project-detail {
    margin-bottom: em(20px);

    .left {
      width: 100%;
      float: right;
      height: auto !important;
    }
    img {
      width: 100%;
      float: right;
      padding-left: 0;
      padding-bottom: em(20px);
    }
  }

  //Events

  //Booking form
  form.event-form {

    .field {
      width: 50%;
      &.email {
        width: 66%;

      }

      &.requirements {
        width: 50%;
      }
    }
  }

  body.event .guest-details .guest .third {
    width: 50%;
  }

  //Fellow pages
  body.fellow, body.profile-edit {

    .fellow-details {
      .left {
        width: 100%;
        padding-right: 0;
        padding-bottom: em(40px);
        img {
          width: 100% !important;
          height: auto;
        }
      }
      .right {
        width: 100%;
        padding-left: 0
      }
    }

    .two-column-list {

      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }

  ///////////////
  //Profile Edit
  ///////////////

  body.profile-edit {
    .fellows-only {
      li {
        width: 100%;
      }
    }

    .checkboxlist {
      li {
        width: 100%;

        &:nth-of-type(2n+1) {
          clear: both;
        }
      }
    }
  }

  //Publications
  .resource-library {

    ul.resources {

      li {
        width: 48.6%
      }
    }
  }

  //Search results
  body.search-results {
    .filters {
      li {
        width: span(1 of 4);
      }
    }
  }
  .search-listing {

    article {
      width: 50%;

      &:nth-of-type(3n+1) {
        padding-left: .4411764706em;
        clear: none;
      }

      &:nth-of-type(3n+3) {
        padding-right: .4411764706em;
      }

      &:nth-of-type(2n+1) {
        padding-left: 0;
        clear: both;
      }

      &:nth-of-type(2n+2) {
        padding-right: em(0);
      }

    }
  }

  //Footer
  footer {
    section img {
      display: inline-block;
      float: none;
    }

    .col3 {
      ul {
        width: 50%;
      }
    }

    .col6 {
      font-size: 0.8em;
    }
  }

}

/* @media screen and (max-width: 414px) */
@media screen and (max-width: 414px) {
  html {
    font-size: 12px;
  }

  //Grid
  .body {
    &:after {
      display: none;
    }

    .left {
      width: 100%;
      padding-right: 0;
    }

    .right {
      width: 100%;
      margin-top: em(20px);
      border-top: 1px solid #000;
      padding-top: em(20px);
      padding-left: 0;
    }
  }

  .content.left {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }

  .cta-holder-right {
    width: 100%;
    margin-top: em(20px);
    border-top: 1px solid #000;
    padding-top: em(20px);
    padding-left: 0;
  }

  //Header
  header {
    .left {
      width: 100%;
      text-align: center;
      padding-bottom: 0;
      a, h4 {
        display: inline-block;
      }
    }
    .right {
      width: 100%;
      text-align: center;
      margin-bottom: 0.5em;

      ul {
        position: absolute;
        right: em(40px);
        top: em(20px);
      }
    }
  }

  #menu-toggle {
    top: em(50px);
  }

  .main.wrapper .container {
    margin-top: 9em;
  }

  //Home
  .hero.home {
    padding-bottom: 80%;
    margin-bottom: 5.5em;
  }

  .slide {
    article {
      &.sub {
        width: 49%;
        height: 48.8%;
        margin-bottom: 1.8%;

        div {
          width: 90%;
        }
      }
      &.main {
        width: 49%;

        div {
          width: 90%;
        }
      }
      &.sub-lrg {
        width: 49%;

        div {
          width: 90%;
        }
      }
    }
  }

  .slide-navigation {
    bottom: -5.4em;
    li {
      width: span(1 of 3);
    }
  }

  .home {
    .secondary {
      width: 100%;
      padding: em(30px) 0;
    }

    .secondary:nth-of-type(2) {
      padding-left: 0;
      border-right: none;
      border-top: 1px solid #111;
      border-bottom: 1px solid #111;
    }
  }

  //Fellows listing pages/ case studies....
  .case-study-list {
    article {
      width: 100%;
    }
  }

  //News Listing
  #newsfilters {
    .sortoptions {
      min-width: 100%;
      div.selector {
        float: right;
        width: 70%;
      }
    }

    .dateoptions {
      float: left;
      margin-left: 0;
      min-width: 100%;
      div.selector {
        width: 70%;
      }
    }
  }

  .news-listing {
    article {
      width: 100%;

      &:nth-of-type(3n+1) {
        border-right: 0;
      }

      &:nth-of-type(2n+1) {
        border-left: 0;
        border-right: 0;

        &.last {
          border-left: 0;
          border-right: 0;
        }
      }

      &:nth-of-type(2n+2) {
        border-left: 0;
        border-right: 0;

        &.last {
          border-left: 0;
          border-right: 0;
        }
      }

      &.mini {
        width: 100%;
        height: auto;
        padding-bottom: em(40px);
      }
    }

    div.summary {
      width: 100%;
      border-right: 0;
      border-left: 0;
      height: auto;
    }
  }

  .pagination {
    .left {
      width: 100%;
      float: right;
      margin-bottom: em(20px);
    }
    .right {
      width: 100%;
    }
  }

  //Fellows Listing
  .fellows-directory {

    .lrg-search {
      input[type=search] {
        font-size: 1em;
        width: 100%;
      }

      a.advanced {
        float: left !important;
        margin-top: 1em;
      }
    }

    .advanced-search {

      .option {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .advanced-search + .pagination {
      border-top: 1px dotted #777;
      padding: em(20px) 0;

      .left {
        li {
          @include span(1 of 10);
          text-align: center;
        }
      }
    }
  }

  .fellows-tiles {
    .boxgrid {
      width: 100% !important;
      padding: 0px;
      }
  }

  //Publications
  .resource-library {

    ul.resources {

      li {
        width: 98.6%
      }
    }
  }

  //Events

  //Booking form
  form.event-form {

    .field {
      width: 100%;
      &.email {
        width: 100%;

      }

      &.requirements {
        width: 100%;
      }
    }

    input.type, .field.email input.type {
      width: 100%;
    }

    textarea {
      width: 100%;
    }
  }

  //Search results
  .search-listing {
    article {
      width: 100%;
      padding-right: 0 !important;
      padding-left: 0 !important;

    }
  }

  //Footer
  aside.cta-holder {
    .left {
      width: 100%;
      margin-bottom: em(20px);
    }
    .right {
      width: 100%;
      margin-bottom: em(20px);
    }
  }

  footer {
    .col1 {
      width: 100%;
      padding-right: 0;
      padding-bottom: em(20px);
    }
    .col2 {
      width: 100%;
      padding: 0;
      padding-bottom: em(20px);
    }
    .col3 {
      width: 100%;
      padding: 0;
      padding-bottom: em(20px);
    }
    .col6 {
      width: 100%;
      padding: 0;
      padding-bottom: em(20px);

      li {
        @include span(1 of 2);
        font-size: 1.3em;
      }
    }

    input[type=text] {
      font-size: 0.8em;
      width: 100%;
    }

    input.btn.grey {
      font-size: 1em;
    }
  }
}

/***********************

  Tom's edits - 19.01.17

***********************/

/* @media screen and (max-width: 850px) */
@media screen and (max-width: 850px) {
    aside.cta-holder{
    section{
      height: auto;
      a{
        img{
          height: 100%;
        }
        p.bottom{
          margin-bottom: 0.2em;
        }
      }
    }
  }
  #menu-toggle{
    top: 2.5em;
  }
  .tabs{
    li.tabHeadingText{
      width: 100%;
    }
  }
  .page-content{
    table{
      width: 100% !important;
    }
  }
  iframe{
    width: 100%;
  }
  header{
    .left{
      h4{
        margin-left: 4.5em;
      }
    }
  }
  .publications{
    .main.wrapper{
      h1:first-of-type{
        margin-top: 120px;
      }
    }
  }
}

@media screen and (max-width: 750px){
  .alt-event-listing{
    .alt-event-listing__content--left{
      flex-basis: 100%;
      text-align: left;
    }
    .alt-event-listing__content{
      width: 100%;
      flex-wrap: wrap;
    }
    .alt-event-listing__content--right {
      -ms-flex-preferred-size: 20%;
      flex-basis: 30%;
      text-align: right;
      margin-left: 70%;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 600px){
  .alt-event-listing{
    flex-wrap: wrap;
    .alt-event-listing--header{
      text-align: center;
    }
    .alt-event-listing__content--left{
      text-align: center;
    }
    .alt-event-listing__info{
      flex-basis: 100%;
      margin-top: 20px;
    }
    .alt-event-listing__image {
      flex-basis: 150px;
      margin-left: calc(50% - 75px);
    }
    .alt-event-listing__content--right{
      margin-left: 30%;
      flex-basis: 40%;
    }
  }
  .alt-list-header{
    flex-wrap: wrap;
    .alt-list-header__right{
      flex-basis: 100%;
    }
  }
  .alt-event-single-container .left-col{
    width: 100%;
  }
  .alt-event-single-container .right-col{
    width: 100%;
  }
  .alt-search__right.full-width{
    margin-left: 0;
  }
  #fellow-tiles-ready .fellows-tiles .boxgrid.fellow{
    padding: 20px 0 !important;
  }
}

/* @media screen and (max-width: 414px) */
@media screen and (max-width: 414px) {

  p{
    font-size: 16px !important;
  }

  .slide-navigation{
    li{
      font-size: 16px;
    }
  }

  .slide{
    article{
      width: 100% !important;
    }
  }

  ul.tweet_list{
    li{
      font-size: 16px;
      line-height: 20px;
    }
  }

  footer{
    .col2{
      font-size: 16px;
      line-height: 25px;
    }
  }

  .case-study-list{
    .boxgrid{
      div{
        width: 100% !important;
      }
    }
  }

  .bread{
    font-size: 14px !important;
  }

  nav#offscreen-nav{
    ul{
      form{
        input.data_field{
          margin: 0.3em;
          background: #fff;
          border-bottom: solid 1px #036;
        }
      }
    }
  }

  #menu-toggle{
    top: 2.25em;
  }

  .tabs{
    li#tab2_link{
      font-size: 12px !important;
    }
  }
  .staff-directory{
    .two-column-list{
      li{
        width: 100%;
      }
    }
  }
  .alt-search__right, .alt-search__left{
    flex-basis: 100%;
  }
  .fellow-tiles{
    .boxgrid{
      width: 100% !important;
    }
  }
  .news-listing{
    article.notmain{
      height: auto;
      .info{
        height: 20%;
      }
    }
  }

  .pagination{
    li{
      span{
        margin-top: 0!important;
        border-bottom: 0!important;
        margin-left: 2px!important;
        padding: 5px 8px!important;
        display: block;
        padding: .2941176471em;
        border-bottom: 1px solid #777;
        background-color: #EEF0EC;
        text-decoration: none;
      }
    }
  }
  .funding-detail{
    section{
      article{
        .left{
          flex-basis: 100% !important;
        }
        .right{
          display: none;
        }
      }
    }
  }

}

/* @media screen and (max-width: 320px) */
@media screen and (max-width: 320px) {

  .slide-navigation{
    li{
      font-size: 14px;
    }
  }

}
