/**********************************************************************

Grid

**********************************************************************/

.wrapper {
    @include container();
    padding: 0 2.6470588235em;
    background: #FFF;
}

.container {
    @include span(full);
    font-size: em($base-font-size);
    line-height: em($line);
}

.body {
    @include span(full);
    margin: 1.1764705882em 0 2.3529411765em;
    position: relative;

    .left {
        @include span(63%);
        padding-right: 1.1764705882em;
    }

    .right {
        @include span(37%);
        padding-left: 1.1764705882em;
    }
}

.content.left {
    @include span(63%);
    padding-right: 1.1764705882em;
    border-right: 1px solid #000;
    margin-bottom: 2.3529411765em;

    &.no-border {
        border-right: 0;
        margin-bottom: em(10px);
    }

    //Newslist single column
    &#newslist {
        width: 100%;
        padding-right: 0;
        border-right: none;
    }
}

.cta-holder-right {
    @include span(37%);
    padding-left: 1.1764705882em;

    > article {
        @include span(full);
        position: relative;
        background: #036;
        height: em(140px);
        overflow: hidden;
        position: relative;
        padding: em(20px) em(20px) em(20px) em(20px);
        margin-bottom: em(20px);

        a {
            display: block;
            padding-right: 55%;
            color: #FFF;

            &:hover * {
                text-decoration: underline;
            }
        }

        h5 {
            margin-bottom: em(5px, 22px);
        }

        p {
            font-size: em(16px);
        }

        p.bottom {
            position: absolute;
            font-size: em(13px, 16px);
            margin-bottom: em(20px, 13px);
            bottom: 0;
        }

        img {
            position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            width: auto !important;
            height: 100% !important;

            &:nth-of-type(2) {
                top: 50%;
                left: 75%;
                margin-top: -24px;
                margin-left: -24px;
                width: 3em !important;
                height: auto !important;
            }
        }
    }

}

.col.full {
    @include span(full);
}

.left {
    float: left;
}

.right {
    float: right;
}

div.selector.fixedWidth span {
    width: 100%;
}

div.selector span {
    color: #111;
}

div.checker {
    margin-top: -0.1em;
}

.flex-space-between {
    width: 100%;
    clear: both;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

}