/* ----------------------------------------------------
 * First
 * ---------------------------------------------------- */
@import "compass/reset";
@import "common/variables";
@import "common/mixins";
//
//
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
// endbower
//
//
/* ----------------------------------------------------
 * Vendor
 * ---------------------------------------------------- */
@import "vendor/uniform._base";
@import "vendor/jquery-te-1.4.0";
@import "uniform.acad";
//
//
/* ----------------------------------------------------
 * Common
 * ---------------------------------------------------- */
@import "common/global";
@import "common/fonts";
@import "common/typography";
@import "common/base";
@import "common/grid";
@import "common/elements";
@import "common/media";
@import "common/forms";
//
//
/* ----------------------------------------------------
 * Layouts
 * ---------------------------------------------------- */
@import "layouts/header";
@import 'layouts/footer';
@import "layouts/home";
@import "layouts/content-pages";
@import "layouts/fellow-pages";
@import "layouts/fellow-login-popup";
@import "layouts/news-listing";
@import "layouts/supporter-recognition";
@import "layouts/fellows-listing_case-studies";
@import "layouts/fellows-search";
@import "layouts/profile_edit";
@import "layouts/projects-listing";
@import "layouts/projects_funding";
@import "layouts/staff-directory";
@import "layouts/events-listing";
@import "layouts/single-events";
@import "layouts/case-studies-listing";
@import "layouts/publications";
@import "layouts/case-studies";
@import 'layouts/fellow-directory';
@import 'layouts/event-listing';
@import 'layouts/event-single';
@import 'layouts/crazy-forms';
@import 'layouts/search-results';
@import 'layouts/tabs';
@import 'layouts/call-to-actions';
//
//
/* ----------------------------------------------------
 * Last
 * ---------------------------------------------------- */
@import "common/responsive";
@import "fancybox";

.tabs{
  border-bottom: 1px solid black;
}

.tabHeadingText{
  border: 1px solid black !important;
  text-align: center !important;
  padding: 10px !important;
  margin-bottom: -1px !important;
  margin-right: 5px !important;
  font-size: 14px !important;
  &::after{
    display: none !important;
  }
  a{
    text-decoration: none !important;
    border-bottom: none !important;
    &::after{
      display: none !important;
    }
  }
  &.active{
    border-bottom: 1px solid white !important;
  }
}

.tabbed-content .tab_content{
  a{
    text-decoration: underline !important;
  }
}
