
html {
  @include border-box-sizing;
  font-size: $base-font-size;
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
    font-family: $colaborate_thin;
  }
}

body {
  background: #eaede8;
  font-size: em($base-font-size);
  line-height: em($line);
}

.table {
  display: table;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  .vmiddle {
    display: table-cell;
    vertical-align: middle;
  }
}

.clear {
  clear: both;
}