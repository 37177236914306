/* ----------------------------------------------------
 * This is where inputs and forms are styled.
 * --------
 * Please try not to deviate too much from the default input styling,
 * Thanks Sam.
 * ---------------------------------------------------- */

//  Site wide input styles
input, .select-container, textarea {
    display: inline-block;
    font-size: 1em !important;
    background: white;
    padding: 10px 15px;
    line-height: 1;
    color: #666;
    border: solid 1px $blue;

    &::placeholder {
        color: inherit !important;
        font-family: inherit !important;
        opacity: 1 !important;
        font-size: 1em !important;
    }
}

label {
    display: block;
    font-size: .9em;
    color: $blue;
    font-weight: bold;
    font-family: $colaborate_regular;
    padding: 0;
    margin: 2px 0;
    width: 100%;
}

// Select styling
.select-container {
    position: relative;
    max-width: 100%;
    width: 100%;


    &:after {
        content: '\f078';
        font-family: 'FontAwesome', serif;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: inherit;
        display: block;
        font-size: .8em;
        pointer-events: none;
    }

    .select2-container--default .select2-selection--single {
        border: none !important;
        padding: 0;
    }

    select {
        background: transparent;
        font-size: 1em;
        line-height: 1;
        width: 100%;
        border: none !important;
        border-radius: 0;
        -webkit-appearance: none;
        padding: 0;
        margin: 0 20px 0 0;

        &:focus {
            outline: none;
        }
    }
}