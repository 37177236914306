/**********************************************************************

CTAs

**********************************************************************/

.cta-right {
  @include span(full);
  position: relative;
  margin-top: em(15px);
  margin-bottom: em(15px);
  border-bottom: 1px dotted #777;

  //realted items, they are extra REAL
  &.realted-items {
    padding-bottom: em(15px);

    a.more {
      margin-top: 0.5em;
      display: table;
    }
  }
  //Oops. I may have corrected the spelling on these.

  .image-over {
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin-bottom: em(20px);
    margin-top: em(5px);

    img {
      width: 100%;
    }
  }

  &.related-media {
    padding-bottom: em(15px);
  }

  h2 {
    margin-bottom: 0;
    font-size: em(26px);
    line-height: 100%;
  }

  h4 {
    padding-bottom: em(15px, 26px);
    color: #333;
    font-size: em(26px);
  }

  //RSS breaks the rules
  &.rss {
    position: relative;
    .right {
      float: left;
      margin-left: em(15px);
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.related-items {
  article {
    margin-bottom: 1em;
  }
}

//Twitter related
.twitter-right {
  h2 a {
    border-bottom: 1px dotted #036;
    color: #036;
    font-size: em(17px, 24px);
  }

  ul.tweet_list {
    li {
      @include span(full);
      padding: em(10px, 16px) em(10px, 16px) em(15px, 16px);
      margin-bottom: em(7px, 16px);
      border: 1px solid #eee;
      color: #555;
      font-size: em(16px);
      line-height: 120%;
    }

    a.tweet_avatar {
      float: right;
      margin: 0 0 em(50px) em(40px);
    }
  }
}

//Related media
.related-media {

  article {
    @include span(full);
    margin: em(15px) 0 em(10px);
  }

  h3 {
    font-family: $colaborate_thin;
    margin-bottom: em(10px);

    img {
      float: left;
      padding-right: em(5px);
    }
    a {
      float: right;
      margin-right: em(5px);
      margin-left: em(15px);
      background: #eee;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
      img {
        float: right;
        padding-right: 0;
        max-width: em(82px);
        height: auto !important;
      }
    }
  }
}

//Related items
.realted-items {
  article {
    padding-bottom: em(20px);
  }
}

ul.tabs-right {
  float: left;
  padding-bottom: 15px;
  width: 100%;

  li {
    float: left;
    border-bottom: 1px dotted #777;

    a {
      h4 {
        color: #777;
      }
      &:hover {
        text-decoration: none;
      }
    }

    &.active {
      border-bottom: 1px dotted #fff;
      h4 {
        color: $blue;
      }
      a {
        opacity: 1;
      }
    }
  }

  li.tab-menu-break {
    margin-right: em(10px);
    width: em(10px);
    height: em(30px);
    border: 0;
    border-right: 1px solid #777;
  }

  h4 {
    color: #333;
    padding-bottom: em(5px, 26px);
    font-size: em(26px);
  }
}

aside.cta-holder {
  @include span(full);

  .left {
    @include span(48%);
  }

  .right {
    @include span(48% last)
  }

  section {
    height: em(140px);
    overflow: hidden;
    position: relative;
    padding: em(20px) em(20px) em(20px) em(20px);
  }

  a {
    display: block;
    padding-right: 55%;
    color: #fff;

    &:hover * {
      text-decoration: underline;
    }
  }

  h5 {
    margin-bottom: em(5px, 22px);
  }

  p {
    font-size: em(16px);
  }

  p.bottom {
    position: absolute;
    font-size: em(13px, 16px);
    margin-bottom: em(20px, 13px);
    bottom: 0;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;

    &:nth-of-type(2) {
      top: 50%;
      left: 75%;
      margin-top: -24px;
      margin-left: -24px;
    }
  }
}

article.staff-member {
  @include span(full);
  margin-bottom: 1em;

  h4 {
    margin-top: em(10px, 22px);
    padding-bottom: 0px;
    font-size: em(22px, 15px);
    margin-bottom: em(5px, 22px);
  }

  h5 {
    margin-bottom: em(10px, 16px);
    font-size: em(16px, 20px);
    font-family: $colaborate_regular;
  }

  img {
    float: right;
    width: em(110px) !important;
    height: auto !important;
    margin-top: em(12px);
    margin-bottom: em(12px);
  }

  p {
    margin-bottom: em(3px);
  }

  .email {
    margin-top: 0.5em;
    a {
      margin: -2px;
      padding: 2px 2px 1px;
      border-bottom: 1px dotted #036;
      &:hover {
        margin: -2px;
        padding: 2px;
        border-bottom: none;
        background: #036;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .more {
    margin-top: 0.2em;
  }
}