/**********************************************************************

Projects and Funding pages

**********************************************************************/

.funding-detail, .project-detail {
  margin-bottom: em(20px);

  .left {
    @include span(1 of 3);
    background-color: #EEF0EC;
    padding: em(15px) em(20px) em(15px);

    .project & {
      color: #fff;
      background-color: $blue;

      p {
        font-size: em(16px);
      }
    }
  }
  img {
    @include span(2 of 3);
    padding-left: em(15px);
    height: auto !important;
  }
}

body.project {
  .related {
    ul {
      margin: 0;
    }
    li {
      list-style: none;
      margin-bottom: em(20px);
    }
  }
}