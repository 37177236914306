/**********************************************************************

Publications

**********************************************************************/
.resource-library {

  //Slight classing fail due to the new template classing scheme
  //This is actually the inner container
  .resource-library {
    float: left;
    width: 102%;
    margin-left: -1%;
  }

  div.selector.fixedWidth {
    width: em(150px, 15px);
  }

  div.selector.fixedWidth span {
    width: em(130px, 15px);
  }

  ul.resources {
    margin: 0;

    li {
      @include span(31.93%);
      position: relative;
      margin: em(7px) 0.7%;
      padding: em(15px) em(10px) em(10px);
      min-height: em(180px);
      border: 1px solid #eee;
      list-style: none;

      p {
        font-size: em(15px);
      }
    }

    h3 {
      float: left;
      clear: both;
      margin-bottom: em(10px);
      width: 100%;
      font-family: $colaborate_thin;
      zoom: 1;

      &.pdf:before {
        margin-right: em(5px);
        content: url('../images/pdf-icon.gif');
      }

    }

    .resourceImage {
      float: right;
      margin-right: em(5px);
      margin-left: em(15px);
      width: em(57px);
      height: auto;
      background: #eee;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

      img {
        width: 100% !important;
        height: 81px !important;
        float: left;
      }
    }

    .downloadInfo {
      p {
        float: left;
        margin-bottom: 0;
        padding: .2em 0 .1333333333em;
      }
      span {
        float: right;
        font-size: em(15px);
      }
    }
  }

  p.intro {
    @include span(98%);
    margin: 0 1% em(10px, 26px);
    border-bottom: 2px solid #036;
    font-size: em(26px);

    a {
      float: right;
      margin: -2px;
      padding: 2px 2px 1px;
      font-size: em(14px, 26px);
      &:hover {
        margin: -2px;
        padding: 2px;
        border-bottom: none;
        background: #036;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}