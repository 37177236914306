/**********************************************************************

Fellows Search

**********************************************************************/

.alt-search__more .more-options-text {
    font-family: $colaborate_regular;
}

.page-content p{
    line-height: 1.4;
}

//Pagination specific overides may be useful to other areas
.pagination {
    .letters {
        li {
            a {
                padding: em(5px) em(2px);
            }
        }
    }

    li {
        &.list-view {
            a {
                background-image: url("../images/fellows-list-view.svg");
                background-size: 18%;
                background-position: em(10px) em(10px);
                background-repeat: no-repeat;
                padding-left: em(35px);
            }
        }

        &.grid-view {
            a {
                background-image: url("../images/fellows-grid-view.svg");
                background-size: 18%;
                background-position: em(10px) em(10px);
                background-repeat: no-repeat;
                padding-left: em(35px);
            }
        }
    }
}

///Search styles are split between two divs
.lrg-search {
    input[type=search] {
        float: left;
        font-size: em(11px);
        padding: em(6px, 11px);
        width: em(200px, 11px);
        height: em(31px, 11px);
        border: 1px solid #777;
        border-radius: 4px;
        background: url("../images/magnif.svg") right center no-repeat;
        background-size: 11%;
        color: #333;
    }

    a.advanced {
        font-size: em(16px);
        display: block;
        float: left;
        padding: em(5px, 16px) em(30px, 16px) 0 em(12px, 16px);
        font-family: 'ColaborateRegular', Arial, sans-serif;
        transition: all 0.5 ease;
        background: url("../images/advanced-search-icon.svg") right center no-repeat;
        background-size: 20%;
    }

    &.panelactive {
        a.advanced {
            float: right;
            margin-right: em(10px);
        }
    }
}

.advanced-search {
    padding: em(7px) 0;
    position: relative;

    .option {
        @include span(1 of 3);

        padding: 0 em(10px) em(10px);

        //label {
        //    @include span(full);
        //
        //    font-family: $colaborate_medium;
        //    font-size: em(15px);
        //    padding: em(8px, 15px) 0 em(5px, 15px);
        //}

        &:nth-of-type(3n+1) {
            padding-left: 0;
            clear: both;
        }

        &:nth-of-type(3n+3) {
            padding-right: 0;
        }

        div.selector {
            width: 100%;

            span {
                width: 100%;
            }
        }

        &.half {
            width: 50%;

            //Cancel previous rule
            &:nth-of-type(3n+1) {
                padding-left: em(10px);
                clear: none;
            }

            &:nth-of-type(3n+3) {
                padding-right: em(10px);
            }

            //New rule
            &:nth-of-type(2n+1) {
                padding-left: 0;
                clear: both;
            }

            &:nth-of-type(2n+2) {
                padding-right: 0;
            }
        }
    }

    .right {
        position: absolute;
        right: 0;
        bottom: em(7px);
    }

    input.btn {
        font-size: em(15px);
        padding-top: em(8px, 15px);
        height: em(32px, 15px);
        letter-spacing: em(0.5px, 15px);
    }
}

.fellows-directory {
    .pagination {
        border-top: 1px dotted #777;
        padding: em(20px) 0;

        li {
            margin-bottom: em(10px);
        }

        span {
            font-size: em(16px);
        }
    }

    .pagination + div {
        @include span(full);
    }
}

.fellows-tiles {
    @include span(full);

    padding: em(20px) 0;

    .boxgrid {
        @include span(1 of 2);

        padding: 0 em(7.5px) em(15px);

        &.left {
            padding-left: 0;
            clear: both;
        }

        &.right {
            padding-right: 0;
        }

        a {
            width: 100%;
            height: em(234px);
            display: block;
            overflow: hidden;
            position: relative;
        }

        .boxcaption {
            position: absolute;
            top: em(176px);
            float: left;
            width: 100%;
            height: em(200px);
            background: #036;
            color: #FFF;
            opacity: .8;
            transition: top .25s ease-in-out;
        }

        .inner {
            position: absolute;
            right: 0;
            left: 0;
            padding: em(10px) em(10px) 0;
            height: 100%;
            text-decoration: none;
        }

        h3 {
            height: em(42px);
        }

        p {
            font-size: em(12px);
            margin-top: em(5px, 12px);
            line-height: 140%;
        }

        p.more {
            position: absolute;
            top: em(100px, 12px);
            font-size: em(12px);
        }

        &:hover {
            .boxcaption {
                top: em(100px);
            }
        }

        //Honrary exception to the rule =P
        &.honorary {
            .boxcaption {
                background-color: #6C6;
            }
        }
    }

    .boxgrid.list-view {
        width: 100%;
        padding-left: 0;
        padding-right: 0;

        h3 {
            height: auto;
        }

        a {
            background: #eef0ec;
            height: auto;

            .inner {
                @include span(full);

                padding: em(15px) em(10px) 0;
                height: 100%;
                position: relative;
            }
        }

        p {
            font-size: 1em;
        }

        p.more {
            top: auto;
            right: em(20px);
            bottom: 0;
        }

        &:hover {
            * {
                color: #fff;
            }

            a {
                background: $blue;
            }
        }
    }

    .pagination {
        padding-top: em(20px);
        border-top: 1px dotted #777;
    }
}
