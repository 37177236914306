@font-face {
  font-family: 'ColaborateThinRegular';
  src: url('../fonts/ColabThi-webfont.eot');
  src: url('../fonts/ColabThi-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ColabThi-webfont.woff') format('woff'),
  url('../fonts/ColabThi-webfont.ttf') format('truetype'),
  url('../fonts/ColabThi-webfont.svg#ColaborateThinRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ColaborateLightRegular';
  src: url('../fonts/ColabLig-webfont.eot');
  src: url('../fonts/ColabLig-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ColabLig-webfont.woff') format('woff'),
  url('../fonts/ColabLig-webfont.ttf') format('truetype'),
  url('../fonts/ColabLig-webfont.svg#ColaborateLightRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ColaborateRegular';
  src: url('../fonts/ColabReg-webfont.eot');
  src: url('../fonts/ColabReg-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ColabReg-webfont.woff') format('woff'),
  url('../fonts/ColabReg-webfont.ttf') format('truetype'),
  url('../fonts/ColabReg-webfont.svg#ColaborateRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ColaborateMediumRegular';
  src: url('../fonts/ColabMed-webfont.eot');
  src: url('../fonts/ColabMed-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ColabMed-webfont.woff') format('woff'),
  url('../fonts/ColabMed-webfont.ttf') format('truetype'),
  url('../fonts/ColabMed-webfont.svg#ColaborateMediumRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'ColaborateBoldRegular';
  src: url('../fonts/ColabBol-webfont.eot');
  src: url('../fonts/ColabBol-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ColabBol-webfont.woff') format('woff'),
  url('../fonts/ColabBol-webfont.ttf') format('truetype'),
  url('../fonts/ColabBol-webfont.svg#ColaborateBoldRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'icons';
  src: url('../fonts/icomoon.eot?-7end5t');
  src: url('../fonts/icomoon.eot?#iefix-7end5t') format('embedded-opentype'),
  url('../fonts/icomoon.woff?-7end5t') format('woff'),
  url('../fonts/icomoon.ttf?-7end5t') format('truetype'),
  url('../fonts/icomoon.svg?-7end5t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-elect:before {
  content: "\e605";
}

.icon-youtube:before {
  content: "\e600";
}

.icon-pinterest:before {
  content: "\e601";
}

.icon-linkedin:before {
  content: "\e602";
}

.icon-twitter:before {
  content: "\e603";
}

.icon-facebook:before {
  content: "\e604";
}