/*
*
*  Simple fluid media
*
*/

figure {
  position: relative;
  border: 1px solid black;
}

figure img, figure object, figure embed, figure video {
  display: block;
  max-width: 100%;
}

.video-block iframe {
  display: block;
  max-width: 100%;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

iframe {
  -webkit-transform: translate3d(0, 0, 0);
}

strong {
  font-weight: normal;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}