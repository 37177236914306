///////////////
//Profile Edit
///////////////

body.profile-edit {

  p.intro {
    @include span(full);
    small {
      font-size: 0.55em;
      line-height: 2.5em;
      float: left;
      clear: both;
    }
    input {
      font-size: 0.55em;
      float: left;
    }
  }

  small {
    @include span(full)
  }

  .content {
    input[type=text], input[type=password] {
      font-size: 0.8em;
      margin-bottom: 0.9em;
      padding: em(6px, 12px);
      line-height: em(16px, 12px);
      width: 90%;
    }
  }

  .socialInput label {
    @include span(full);
    font-size: em(12px);
    margin: em(-12px, 12px) 0 em(10px, 12px);
  }

  textarea {
    font-size: 0.8em;
    width: 100% !important;
  }

  p.fellow-spec {
    @include span(full);
  }

  .fellows-only {
    background: #eee;
    padding: em(10px);
    margin: 0 -1.2%;
    width: 102%;

    textarea {
      width: 90% !important;
    }

    li {
      @include span(1 of 2);
    }
  }

  p.sub {
    @include span(full);
  }

  .checkboxlist {
    @include span(full);
    margin-bottom: em(20px);

    li {
      @include span(1 of 2);
      margin-bottom: em(5px);
      padding-right: em(10px);
      s
      &:nth-of-type(2n+1) {
        clear: both;
      }

      &.answerOther {
        width: 100% !important;
        padding: 0 !important;
        display: none;

        label {
          padding-right: em(10px);
        }

        input {
          width: 40%;
        }
      }
    }

    &.one-column {
      li {
        width: 100%;
      }
    }

    div.checker {
      float: left;
      height: 100%;
      margin-top: 0.05em;
    }
  }
}