/**********************************************************************

Fellows listing pages/ case studies....

**********************************************************************/

.nihr {
  .boxcaption {
    background: #692E7E !important;
  }

  span.si {
    font-family: $colaborate_medium;
    margin-top: -1.8em;
    float: left;
    font-size: 0.9em;
  }
}

.case-study-list {
  @include span(full);
  padding-bottom: em(15px);

  article {
    @include span(1 of 3);
    height: 0;
    position: relative;
    border: solid #fff;
    padding-bottom: 33%;
    border-width: 0 em(5px) em(10px);
    overflow: hidden;

    img {
      max-width: 200%;
    }

    .boxcaption {
      position: absolute;
      top: 60%;
      right: 0;
      bottom: 10%;
      left: 0;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.2s ease-in-out;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      color: $blue;
      padding: em(10px);
      &:hover {
        text-decoration: none;
      }
    }

    &:hover .boxcaption {
      top: 0;
      bottom: 0;
    }
  }
}