/**********************************************************************

Events

 **********************************************************************/
h3.event-form {
  padding: em(10px, 26px) 0 em(5px, 26px);
  border-bottom: 1px dotted #777;
  font-size: em(26px);
}

//Booking form
form.event-form {

  .fellow-check {
    float: right;
    margin: em(-27px) em(-5px) 0 0;
    width: 42%;
  }

  .guest {
    background-color: #EEF0EC;
    overflow: hidden;
    margin-top: em(10px);
    padding: em(5px) em(10px) em(10px);

    h3 {
      margin-top: em(5px);
    }
  }

  label {
    float: left;
    width: 84%;
  }

  textarea {
    width: 90%;
    height: em(60px, 12px);
    background: #fff;
  }

  div.selector {
    width: 90% !important;
  }

  div.selector.fixedWidth span, div.selector span {
    width: 100% !important;
  }

  input.type {
    float: left;
    padding: em(8px);
    width: 85%;
    border: 0;
    border: 0;
    background-color: #FFF;
    color: #999;
    font-size: 1em;
    height: 2em;
  }

  .field {
    float: left;
    margin-bottom: em(10px);
    width: 33%;
    &.email {
      width: 66%;

      input.type {
        width: 93%;
      }
    }

    &.requirements {
      width: 50%;
    }
  }
}

#addguest.more {
  float: right;
  font-family: $colaborate_medium;
  border: 1px dotted #036;
  padding: em(7px, 17px) em(8px, 17px) em(5px, 17px);
  font-size: em(17px);
  margin-top: em(6px, 17px);
  margin-right: em(15px, 17px);
}

body.event, body.news {
  button.grey, input.btn.grey {
    display: inline;
    float: right;
    margin-top: em(6px, 17px);
    padding: enm(10px, 17px);
    border-radius: 2px;
    background: #036;
    font-size: em(17px, 17px);
    font-family: $colaborate_medium;
    background: linear-gradient(to bottom, #036 0%, #004993 100%);
    color: #FFF;

    span {
      font-family: $colaborate_medium;
    }
  }
}

body.event, body.news {
  .detail {

    h3 {
      padding: em(60px, 26px) 0 em(5px, 26px);
      border-bottom: 1px dotted #777;
      font-size: em(26px);
    }
  }

  .dialogue {
    .event {
      @include span(full);
    }

    a.more {
      float: right;
      margin-bottom: 0.2em;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }

  dl {
    @include span(full);
    margin-bottom: 0.5em;
    dd {
      float: left;
      width: 80%;
      span {
        float: left;
        color: $blue;
        margin-right: 0.6em;
        font-family: $colaborate_medium;
      }
    }
    dt {
      float: right;
      text-align: right;
      width: 20%;
      padding-right: 1em;
    }
  }

  .guest-details {

    .guest {
      @include span(full);
      margin-bottom: em(10px);
      padding: em(15px) em(15px) em(10px) em(10px);
      background-color: #EEF0EC;

      h4 {
        float: right;
        margin-bottom: em(5px, 18px);
        font-size: em(18px);
      }

      .name {
        margin-bottom: em(20px, 20px);
        font-size: em(20px);
      }

      .third {
        @include span(1 of 3);
      }

      .requirement {
        @include span(1 of 2);
      }

      .total {
        @include span(1 of 3 last);
        p {
          margin-bottom: em(5px, 22px);
          font-size: em(22px);
        }
        span {
          float: right;
        }
      }
    }
  }

  // // //
  // event downloads
  // // //
  .event-downloads {
    font-family: $colaborate_regular;
    display: block;
    width: 100%;
    margin-top: 40px;
    color: $blue;
  }

  .event-downloads__heading {
    font-family: $colaborate_regular;
    margin-bottom: 15px;
  }

  .event-downloads__link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $grey;
    width: 100%;
    padding: 10px 15px;
    margin: 0 0 5px 0;
    color: $blue;

    span {
      line-height: 1;
      margin: 0;
      padding: 0;
      display: inline-flex;
      align-items: center;
      font-family: $colaborate_regular;

      img {
        margin-left: 10px;
      }
    }
  }
}













