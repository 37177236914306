/**********************************************************************

Fellow pages

**********************************************************************/

body.fellow, body.profile-edit, .staff-member {

  .content section {
    @include span(full);
    padding-bottom: em(20px);
  }

  .fellow-details {
    margin-top: em(25px);
    .left {
      width: 50%;
      padding-right: em(10px);
    }
    .right, ul.intro {
      float: right;
      width: 50%;
      padding-left: em(10px);
    }

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .fellow-spec {
    strong {
      margin-bottom: em(8px);
    }
  }

  p.intro {
    margin-top: em(-6px);
  }

  .two-column-list {
    @include span(full);

    .left {
      @include span(1 of 2);
    }
    .right {
      @include span(1 of 2);
    }
  }
}

#social-info {
  ul {
    margin-left: 0;
  }
  li {
    list-style: none;
  }
}
