/*****************************************************************************

Supportor recognition

/*****************************************************************************/

.supportor-recognition article.support-high, .supportor-recognition article.support-med {
  display: table;
  float: left;
}

.supportor-recognition article.support-high {
  width: 50%;
  height: em(200px);
}

.supportor-recognition article.support-high:nth-of-type(2n+2):after, .supportor-recognition article.support-med:nth-of-type(3n+3):after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: " ";
  font-size: 0;
}

.supportor-recognition article.support-med {
  width: 33%;
  height: em(150px);
}

.supportor-recognition article.support-high h3 {
  display: none;
}

.supportor-recognition a {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}

.supportor-recognition article img {
  margin-bottom: 0;
}

.supportor-recognition article.support-high img {
  width: em(175px);
  height: auto;
}

.supportor-recognition article.support-med img {
  width: em(125px);
  height: auto;
}