$lightBlue: #DFE3E9;
$midBlue: #BBC2D0;
$midDarkBlue: #0D3068;
$darkBlue: #036;
$weirdBlue: #B4B9CC;
$lightGrey: #EEF0EC;
$midGrey: #E9EBE8;

.alt-search {
    background: $lightBlue;

    //.form_control {
    //  -webkit-appearance: none;
    //  border: none;
    //  background: #FFF;
    //  font-size: 14px;
    //  padding: 10px;
    //  height: 40px;
    //  line-height: 40px;
    //  margin: 0 auto 0 20px;
    //  width: 300px;
    //  max-width: 100%;
    //}

    form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__left, &__right {
        padding: 30px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__left {
        flex-basis: 70%;
        background: $lightBlue;

        h3 {
            padding: 0;
            margin: 0;
            font-family: $colaborate_regular;
        }

        input {
            width: 100%;
            max-width: 313px;
        }

        &.full-width {
            justify-content: space-between;
            flex-basis: 100%;

            h3 {
                margin-right: 38px;
            }

            .alt-search__more {
                flex-grow: 1;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
            }
        }
    }

    &__right {
        flex-basis: 30%;
        background: $midBlue;
        position: relative;

        &::before {
            position: absolute;
            right: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 20px 15px 0;
            border-color: transparent $midBlue transparent transparent;
            content: "";
            display: block;
        }

        &.full-width {
            margin-left: 70%;
        }
    }
}

.alt-btn {
    font-size: inherit;

    &.btn-blue {
        background: $darkBlue;
        color: #FFF;
        border: 0;
        padding: 10px 20px;
        letter-spacing: 1px;
        line-height: 1;
        height: auto;
        margin: 0 auto;
        width: auto;
        max-width: auto;
    }
}

.alt-search__more {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    line-height: 20px;
    cursor: pointer;

    .weirdBlue {
        padding-top: 1px;
    }
}

.plus-additional {
    margin-right: 10px;
}

.lightBlue {
    color: $lightBlue;
}

.midBlue {
    color: $midBlue;
}

.darkBlue {
    color: $darkBlue;
}

.weirdBlue {
    color: $weirdBlue;
}

.blocked {
    padding: 5px 60px 5px 5px;
    background: $lightGrey;
    margin-right: 3px;
}

.pagination {
    .left {
        margin-bottom: 10px;
    }

    .right {
        ul {
            border-top: none;
            padding: 0;
        }
    }

    li {
        span, a {
            margin-top: 0 !important;
            border-bottom: 0 !important;
            margin-left: 2px !important;
            padding: 5px 8px !important;
        }
    }

    li.grid-view, li.list-view {
        a {
            padding-left: 2em !important;
            background-size: 15% !important;
        }
    }
}

.fellows-tiles .boxgrid:nth-child(0n), .fellows-tiles .boxgrid:nth-child(3n + 1), .fellows-tiles .boxgrid:nth-child(3n - 1), .fellows-tiles .boxgrid:nth-child(3n) {
    &.list-view {
        padding: 0 !important;
        margin-bottom: 10px;
    }
}

.letters {
    display: inline-block;
}

.list-fellow {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background: $lightGrey;
    display: flex;

    a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        width: 100%;
    }

    &__left, &__right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        * {
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    &__left {
        flex-basis: 85%;
        align-content: center;
        padding-left: 20px;

        * {
            color: $midDarkBlue;
        }

        h3 {
            padding-bottom: 10px;
        }
    }

    &__right {
        align-items: center;
        flex-basis: 15%;
        background: $midGrey;
        text-align: center;

        * {
            width: 100%;
        }
    }

    .list-fellow__left__img {
        display: inline-block;
        height: 100px;
        width: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: -20px;
    }

    .list-fellow__left__text {
        width: calc(100% - 100px);
        padding-left: 20px;
    }
}

.alt-search__options {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: $blue;

    &.full-width {
        display: flex;
    }

    //label {
    //    display: block;
    //    font-family: $colaborate_regular;
    //    font-size: 0.8em;
    //    margin-bottom: 0;
    //    padding-bottom: 0;
    //}

    select {
        width: 100% !important;
    }

    //.select2-selection {
    //  height: 40px !important;
    //  border: none !important;
    //  border-radius: 0 !important;
    //}
    //
    //.select2-selection__rendered {
    //  line-height: 40px !important;
    //}

    //.select2-selection__arrow {
    //  height: 40px !important;
    //}

    &--left, &--mid, &--right {
        padding: 15px 0;
        flex-basis: 100%;

        @include min(786px) {
            flex-basis: 30%;
            max-width: 30%;
        }
    }
}

.select2-container {
    width: 100% !important;
}
